@keyframes floatAround {
  0%,
  100% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(5px, -7px);
  }
  30% {
    transform: translate(-7px, 7px);
  }
  45% {
    transform: translate(2px, 5px);
  }
  60% {
    transform: translate(-4px, 7px);
  }
  75% {
    transform: translate(7px, -5px);
  }
  90% {
    transform: translate(3px, -3px);
  }
}

.imageContainer {
  // animation: floatAround ease-in-out 8s infinite;
  display: inline-block;
  position: relative;
}

.imageOverlay {
  position: absolute;
  left: 0;
  top: 0;
}
