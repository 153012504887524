@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '/app/javascript/stylesheets/colors';
@import '/app/javascript/stylesheets/fonts';

@layer base {
  *,
  ::before,
  ::after {
    --tw-ring-color: #3b82f6; /* blue-500 */
    --tw-ring-opacity: 0.5;
    border-color: #e5e7eb; /* gray-200 */
  }
}
