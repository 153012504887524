.tiptap {
  color: #171717; // neutral-900
  font-size: 16px;
  font-weight: normal;
}

.tiptap .is-editor-empty:first-child::before {
  color: #d4d4d4; // neutral-300
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
