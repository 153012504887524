.thumbnailContainer {
  @apply tw-mr-3 tw-flex tw-h-screen tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-md tw-bg-peppercorn-50 tw-align-middle;

  height: 50px;
  min-height: 50px;
  min-width: 50px;
  width: 50px;
}

.thumbnailImage {
  @apply tw-overflow-hidden tw-object-contain tw-align-middle;

  height: 50px;
  width: 50px;
}

.placeholderImage {
  @apply tw-overflow-hidden tw-align-middle;

  height: 28px;
  transform: rotate(-10deg);
  width: auto;
}
