$alert-attention-blue: #bce8fe;
$alert-danger-pink: #ffb7ab;
$alert-success-green: #a6fcd4;
$alert-warning-yellow: #ffe5b1;
$charcoal-gray: #101113;
$completed-gray: #9ea7ad;
$dove-gray: #aca5ac;
$dp-dark-green: #009672;
$dp-green: #00bc8f;
$in-progress-blue: #2fcff4;
$in-queue-gray: #f5f4f6;
$lightest-green: #99e4d2;
$lightest-red: #ffb1a5;
$lightest-yellow: #ffe5b1;
$needs-attention-yellow: #ffb302;
$pearl-gray: #f5f4f6;
$purple: #495ce8;
$red: #ff6047;
$review-green: #56f000;
$smoke-gray: #737373;
$squash-yellow: #febe3c;
$text-attention-blue: #51778a;
$text-bold-attention-blue: #334b57;
$text-bold-danger-burgundy: #4c2f2a;
$text-bold-success-green: #2f5141;
$text-bold-warning-brown: #51452e;
$text-danger-burgundy: #84524a;
$text-success-green: #4d8269;
$text-warning-brown: #806e49;
$white: #fff;

:root {
  --color-cornflower-50: #edeffd;
  --color-cornflower-100: #dbdefa;
  --color-cornflower-200: #b6bef6;
  --color-cornflower-300: #929df1;
  --color-cornflower-400: #6d7ded;
  --color-cornflower-500: #495ce8;
  --color-cornflower-600: #3a4aba;
  --color-cornflower-700: #2c378b;
  --color-cornflower-800: #1d255d;
  --color-cornflower-900: #0f122e;
}
