.video-js {
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  font-family: 'proxima-nova', sans-serif !important;

  .vjs-big-play-button {
    border: 0.09em solid !important;
    border-radius: 0.95em !important;
  }

  &.vac-active {
    .vjs-mouse-display,
    .vjs-big-play-button {
      display: none !important;
    }

    .vac-marker-owrap,
    .vac-control {
      display: block;
    }

    .vjs-control-bar {
      display: flex;
      z-index: 2;
    }

    .vac-player-btn i.vac-player-icon svg {
      fill: #00bc8f !important;
    }
  }

  &.vac-disable-play .vjs-control {
    cursor: default;
  }

  .vac-hidden {
    display: none !important;
  }

  &.vac-disable-fullscreen .vac-player-btn {
    display: none;
  }

  &.vac-disable-play {
    .vac-marker {
      display: none !important;
    }

    .vjs-play-control,
    .vjs-play-progress {
      visibility: hidden !important;
    }
  }

  .vac-button {
    transition: all 0.2s ease-in-out;
    margin: 10px;
    line-height: 27px;
    font-size: 13px;
    padding: 0;
    width: calc(100% - 20px);
    text-align: center;
    border-radius: 2px;
    background: #00bc8f;
    color: white;
    display: block;
    cursor: pointer;

    &:hover {
      background-color: #2ca46c;
    }
  }

  .vac-video-cover {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 0, 0, 0.15);
    cursor: crosshair;

    .vac-video-cover-canvas {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  .vac-video-write-new-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.7);

    &.vac-new-comment {
      background-color: rgba(0, 0, 0, 0.4);
    }

    .vac-video-write-new {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        width: 80%;
        max-width: 400px;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.85);
        padding: 20px;
      }

      > div h5 {
        margin: 0;
        padding: 0;
        font-size: 15px;
        color: #000;
        font-weight: normal;
      }

      .vac-comment-showbox {
        border-radius: 1px;
        background-color: white;
        margin-top: 20px;
        width: 100%;
        border-bottom: 2px solid #00bc8f;

        textarea {
          border: none;
          font-size: 13px;
          color: #383838;
          line-height: 16px;
          padding: 8px;
          box-sizing: border-box;
          min-height: 110px;
          width: 100%;
          resize: none;
        }

        > div {
          padding: 10px;
          box-sizing: content-box;
          height: 27px;

          button {
            float: right;
            width: auto;
            padding: 0 40px;
            margin: 0;
          }

          a {
            float: right;
            font-size: 12px;
            text-decoration: underline;
            line-height: 27px;
            display: inline-block;
            color: #383838;
            cursor: pointer;
            margin-right: 20px;

            &:hover {
              color: #ff0000;
            }
          }
        }
      }

      &.vac-is-comment {
        display: block;

        .vac-comment-showbox {
          position: absolute;
          padding: 0;
          margin: 0;
          max-width: none;
        }
      }
    }
  }

  .vac-shape {
    position: absolute;
    z-index: 1;
    background-color: rgba(58, 203, 135, 0.65);
    border: 2px dotted #00bc8f;
  }

  .vac-controls,
  .vac-add-controls {
    z-index: 3;
    position: absolute;
    border-radius: 2px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    top: 4px;
    left: 4px;
    padding: 4px 0 0 0;
    font-size: 14px;
    color: white;
    width: 150px;
    display: none;
    text-align: center;

    i {
      color: #dbdcdc;
      font-size: 11px;
      font-style: normal;
      display: block;
      padding: 4px 0 0;
    }

    button {
      transition: all 0.2s ease-in-out;
      margin: 10px;
      line-height: 27px;
      font-size: 13px;
      padding: 0;
      width: calc(100% - 20px);
      text-align: center;
      border-radius: 2px;
      background: #00bc8f;
      color: white;
      display: block;
      cursor: pointer;

      &:hover {
        background-color: #2fae72;
      }
    }
  }

  .vac-controls.vac-add-controls button,
  .vac-add-controls.vac-add-controls button {
    background-color: #ffffff;
    color: #383838;
  }

  .vac-controls.vac-add-controls button:hover,
  .vac-add-controls.vac-add-controls button:hover {
    background-color: #e6e4e8;
  }

  .vac-controls.vac-add-controls a,
  .vac-add-controls.vac-add-controls a {
    margin: 3px auto 8px;
    text-transform: uppercase;
    display: inline-block;
    color: white;
    cursor: pointer;
    text-decoration: underline;
    font-size: 10px;
  }

  .vac-controls.vac-add-controls a:hover,
  .vac-add-controls.vac-add-controls a:hover {
    color: #fff;
  }

  .vac-controls,
  .vac-add-controls {
    .vac-annotation-nav,
    .vac-video-move {
      margin-top: 8px;
      width: 100%;
      display: flex;
      flex-direction: row;

      div {
        flex: 1;
        background-color: rgba(0, 0, 0, 0.8);
        text-align: center;
        line-height: 22px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        font-size: 12px;

        &:hover {
          background-color: #fff;
          color: #000;
        }

        &.vac-a-prev {
          margin-right: 1px;
        }
      }
    }
  }

  .vac-cursor-tool-tip {
    position: absolute;
    border-radius: 100%;
    z-index: 999;
    display: inline-block;
    white-space: nowrap;
  }

  .vac-player-btn {
    margin-right: 8px;
    cursor: pointer;

    i.vac-player-icon {
      svg {
        fill: white;
        transition: fill 0.2s ease-in-out;
      }

      &:hover svg {
        fill: #00bc8f;
      }
    }

    b {
      position: absolute;
      display: inline-block;
      top: 3px;
      right: 3px;
      color: #fff;
      height: 14px;
      line-height: 14px;
      min-width: 14px;
      text-align: center;
      border-radius: 9px;
      font-size: 10px;
      font-weight: normal;
      background-color: #ff6047;
    }
  }

  .vac-marker-owrap {
    display: none;
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .vac-marker-wrap {
      margin: 0 10px;
      height: 100%;
      position: relative;
      flex: auto;

      .vac-marker,
      .vac-marker-draggable {
        position: absolute;
        width: 7px;
        margin-left: 0;
        height: 30px;
        bottom: 6px;
        background: #00bc8f;
        border-radius: 2px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &.vac-hovering,
        &.vac-force-tooltip {
          opacity: 1 !important;
          background: #2fae72;
          height: 30px;

          > div {
            display: block;
            opacity: 1;
          }

          &.vac-ranged-marker {
            box-shadow: 7px 0 0 0 #eed428 inset;
            background-color: rgba(238, 212, 40, 0.65);
          }
        }

        &.vac-active {
          background-color: #2fae72;
        }

        &.vac-active.vac-ranged-marker {
          box-shadow: 7px 0 0 0 #eed428 inset;
          background-color: rgba(238, 212, 40, 0.65);
        }

        &.vac-ranged-marker {
          box-shadow: 7px 0 0 0 #00bc8f inset;
          background-color: rgba(76, 218, 95, 0.65);
        }

        &.vac-marker-draggable {
          box-shadow: 7px 0 0 0 #e6e4e8 inset;
          background-color: rgba(230, 228, 232, 0.65);
          cursor: ew-resize;
          min-width: 7px !important;
        }

        > div {
          width: 100%;
          position: relative;
          display: none;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        > div span {
          text-overflow: ellipsis;
          white-space: nowrap;
          border-bottom: 1px solid #00bc8f;
          display: block;
          position: absolute;
          max-width: 250px;
          bottom: -1px;
          line-height: 26px;
          height: 28px;
          overflow: hidden;
          padding: 0 5px;
          box-sizing: border-box;
          border-radius: 1px;
          background: rgba(0, 0, 0, 0.8);
          color: white;
          font-family: 'proxima-nova', sans-serif !important;
          font-size: 12px;
          left: 0;
        }

        > div span b {
          color: #00bc8f;
          display: inline-block;
          margin-right: 5px;
          font-weight: normal;
        }

        > div span.vac-right-side {
          left: auto;
          right: 0;
        }
      }

      &.vac-dim-all .vac-marker {
        height: 30px;
        opacity: 0.4;
      }

      &.vac-dim-all .vac-marker.vac-force-tooltip > div {
        display: none;
      }
    }
  }

  .vac-comments-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 15%;
    min-width: 225px;
    z-index: 11;
    background-color: rgba(255, 255, 255, 0.6);
    height: calc(100% - 42px);
    box-sizing: border-box;
    transition: all 0.15s linear;

    &:hover,
    &.vac-active {
      width: 60%;
      max-width: 480px;

      .vac-comments-wrap {
        opacity: 1;
      }

      &.vac-active .vac-add-new-shapebox {
        display: block;
      }

      &.vac-active .vac-reply-btn {
        display: none;
      }
    }

    .vac-comments-wrap {
      opacity: 0.9;
      height: calc(100% - 26px);
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      padding: 10px;
      border-left: 1px solid #dbdcdc;

      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #383838;
      }

      .vac-comment {
        border-radius: 1px;
        box-shadow: none;

        .vac-comment-header {
          width: 100%;
          padding: 2px 5px;
          color: #fff;
          background-color: #383838;
          text-align: left;

          &:hover .vac-delete-comment {
            display: inline;
          }

          .vac-author-name,
          .vac-timestamp {
            display: inline-block;
            line-height: 16px;

            &.vac-author-name,
            &.vac-author-name {
              font-weight: bold;
              text-align: left;
              font-size: 11px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &.vac-timestamp {
              float: right;
              text-align: right;
              font-size: 10px;
            }
          }

          .vac-delete-comment {
            display: none;
            cursor: pointer;
            color: #ff0000;
          }
        }

        .vac-comment-body {
          width: 100%;
          padding: 8px;
          box-sizing: border-box;
          margin-bottom: 10px;
          background-color: #fff;
          color: #000;
          font-size: 13px;
          line-height: 16px;
          text-align: left;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          border-bottom: 1px solid white;
        }
      }

      .vac-reply-btn {
        margin: 10px 0 0 0;
        width: 100%;
      }

      .vac-add-new-shapebox {
        margin: 10px 0 0 0;
        border-radius: 2px;
        height: 157px;
        padding: 0;
        color: orange;
        display: none;
      }
    }

    .vac-comments-control-bar {
      height: 26px;
      border-bottom: 1px solid #00bc8f;
      line-height: 26px;
      padding: 0 10px;
      box-sizing: border-box;
      width: 100%;
      background: #000;

      .vac-range,
      .vac-control-buttons {
        display: inline-block;
        width: 49%;

        &.vac-range,
        &.vac-range {
          float: left;
          text-align: left;
          color: #00bc8f;
        }

        &.vac-range b,
        &.vac-range b {
          color: white;
        }
      }

      .vac-control-buttons {
        float: right;
        text-align: right;
        font-size: 10px;

        a {
          margin: 0 4px;
          cursor: pointer;
          color: #fff;

          &.vac-close-comment-list {
            color: white;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .vjs-16-9,
    .vjs-4-3,
    .vjs-fluid {
      z-index: 8;
    }

    .vjs-slider {
      background-color: #73859f;
    }
  }
}
