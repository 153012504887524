@import '/app/javascript/stylesheets/colors';

.filtersContainer {
  padding: 1rem;
}

.filtersHeader {
  color: $charcoal-gray;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 0.5rem;
}

.filtersIcon {
  margin-right: 0.5rem;
}

.filters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
}

.clearIcon {
  color: $purple;
  cursor: pointer;
  font-weight: 400;
  text-align: right;
  span {
    margin-right: 0.25rem;
  }
}

.clearIcon:focus,
.clearIcon:hover {
  color: #2548fa;
}
